import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Wrapper, ContentWrapper, TeamWrapper, CardWrapper, StyledImg,
} from './styles';

class TeamSection extends Component {
  renderCard = ({ node }, index) => {
    const {
      name, position, text, image: { path },
    } = node.entry;
    return (
      <CardWrapper key={index}>
        <StyledImg src={`http://cockpit.novotalk.com${path}`} />
        <h5>
          {name}
        </h5>
        <h6>
          {position}
        </h6>
        <p>
          {text}
        </p>
      </CardWrapper>
    );
  }

  render() {
    const { content, data } = this.props;
    return (
      <Wrapper>
        <ContentWrapper>
          <div dangerouslySetInnerHTML={{ __html: content }} />
          <TeamWrapper>
            {data.map(this.renderCard)}
          </TeamWrapper>
        </ContentWrapper>
      </Wrapper>
    );
  }
}

TeamSection.propTypes = {
  content: PropTypes.string,
  data: PropTypes.array,
};

export default TeamSection;
