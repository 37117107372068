import styled from '@emotion/styled';

export const Wrapper = styled.section({
  width: '100%',
  marginBottom: 150,
  '@media (max-width: 480px)': {
    marginBottom: 60,
  },
});

export const ContentWrapper = styled.div({
  width: '65%',
  marginLeft: '20%',
  '& h2': {
    textAlign: 'center',
    marginBottom: 35,
  },
  '& p': {
    fontSize: 20,
    marginBottom: 20,
    lineHeight: '24px',
  },
  '@media (max-width: 480px)': {
    width: '100%',
    marginLeft: 0,
    padding: '0 10%',
    '& p': {
      textAlign: 'center',
      fontSize: 15,
      lineHeight: '19px',
    },
  },
},
({ theme }) => ({
  '& h2': {
    color: theme.colors.textHighlighted,
  },
  '& p': {
    color: theme.colors.textGray,
  },
}));
