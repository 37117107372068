import styled from '@emotion/styled';

export const Wrapper = styled.section({
  width: '100%',
  marginBottom: 50,
  '@media (max-width: 480px)': {
    marginBottom: 40,
  },
});

export const ContentWrapper = styled.div({
  padding: '0 16%',
  '& h2': {
    textAlign: 'center',
    marginBottom: 35,
  },
  '& p': {
    marginBottom: 25,
    fontSize: 21,
    lineHeight: '30px',
  },
  '@media (max-width: 480px)': {
    padding: '0 36px',
    '& p': {
      marginBottom: 20,
      fontSize: 15,
      lineHeight: '19px',
    },
  },
},
({ theme }) => ({
  '& h2': {
    color: theme.colors.textHighlighted,
  },
  '& p': {
    color: theme.colors.textGray,
  },
}));
