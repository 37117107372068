import styled from '@emotion/styled';

export const Wrapper = styled.section({
  width: '100%',
  marginBottom: 104,
  paddingTop: 100,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '@media (max-width: 480px)': {
    minHeight: '100vh',
    marginBottom: 40,
  },
},
({ theme }) => ({
  minHeight: `calc(100vh - ${theme.sizes.headerHeight}px)`,
  backgroundColor: theme.colors.backgroundMedium,
  boxShadow: theme.shadows.heroMobile,
  '@media (max-width: 480px)': {
    paddingTop: theme.sizes.headerMobileHeight + 52,
    marginTop: -theme.sizes.headerMobileHeight,
  },
}));

export const ContentWrapper = styled.div({
  width: 658,
  marginLeft: '18%',
  '& h1': {
    fontSize: 40,
    fontWeight: 'normal',
    marginBottom: 12,
  },
  '& p': {
    fontSize: 21,
    lineHeight: '25px',
  },
  '@media (max-width: 480px)': {
    width: '100%',
    marginLeft: 0,
    padding: '0 10%',
    textAlign: 'center',
    '& h1': {
      fontSize: 29,
      marginBottom: 18,
      letterSpacing: '-1.5px',
    },
    '& p': {
      fontSize: 15,
      lineHeight: '19px',
    },
  },
},
({ theme }) => ({
  '& h1': {
    color: theme.colors.textHighlighted,
  },
  '& p': {
    color: theme.colors.textGray,
  },
}));

export const StyledImage = styled.img({
  width: '100%',
  '@media (max-width: 480px)': {
    display: 'none',
  },
});

export const StyledMobileImage = styled.img({
  width: '100%',
  display: 'none',
  '@media (max-width: 480px)': {
    display: 'block',
    marginTop: 50,
    marginBottom: 50,
  },
});
