import styled from '@emotion/styled';

export const Wrapper = styled.section({
  width: '100%',
  marginBottom: 186,
  '@media (max-width: 480px)': {
    marginBottom: 96,
  },
});

export const ContentWrapper = styled.div({
  padding: '0 18%',
  textAlign: 'center',
  '& h2': {
    marginBottom: 50,
  },
  '@media (max-width: 480px)': {
    padding: '0 10%',
  },
},
({ theme }) => ({
  '& h2': {
    color: theme.colors.textHighlighted,
  },
}));

export const TeamWrapper = styled.div({
  display: 'grid',
  gridTemplateRows: 'auto',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridGap: 134,
  '@media (max-width: 480px)': {
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '40px 10px',
  },
});

export const CardWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& h5': {
    marginBottom: 10,
  },
  '& h6': {
    marginBottom: 10,
    fontWeight: 'bold',
  },
  '@media (max-width: 480px)': {
    '& h5': {
      marginBottom: 3,
      fontSize: 12,
    },
    '& h6': {
      fontSize: 12,
    },
    '& p': {
      fontSize: 15,
      lineHeight: '22px',
    },
  },
},
({ theme }) => ({
  '& h5': {
    color: theme.colors.textHighlighted,
  },
  '& h6, & p': {
    color: theme.colors.textGray,
  },
}));

export const StyledImg = styled.img({
  width: 170,
  height: 170,
  marginBottom: 16,
  borderRadius: '50%',
  objectFit: 'cover',
  opacity: 0.46,
  '@media (max-width: 480px)': {
    width: 53,
    height: 53,
  },
});
