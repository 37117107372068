import styled from '@emotion/styled';

export const Wrapper = styled.section({
  width: '100%',
  marginBottom: 186,
  '@media (max-width: 480px)': {
    marginBottom: 66,
  },
});

export const ContentWrapper = styled.div({
  padding: '0 13%',
  '& h2': {
    textAlign: 'center',
    marginBottom: 35,
  },
  '@media (max-width: 480px)': {
    padding: '0 25px',
    '& h2': {
      textAlign: 'center',
      marginBottom: 30,
    },
  },
},
({ theme }) => ({
  '& h2': {
    color: theme.colors.textHighlighted,
  },
}));

export const CarouselWrapper = styled.ul({
  listStyle: 'none',
  '& .slick-dots li button:before': {
    display: 'block',
    content: '""',
    width: 10,
    height: 10,
    borderRadius: '50%',
    opacity: 1,
  },
  '& .slick-dots li.slick-active button:before': {
    border: 'none',
  },
},
({ theme }) => ({
  '& .slick-dots li button:before': {
    border: `1px solid ${theme.colors.textGray}`,
  },
  '& .slick-dots li.slick-active button:before': {
    background: theme.colors.textHighlighted,
  },
}));

export const CarouselItemContainer = styled.li({
  outline: 'none',
});

export const CarouselItem = styled.div({
  margin: '0 67px',
  '@media (max-width: 480px)': {
    margin: '0 11px',
  },
});

export const StyledImg = styled.img({
  marginBottom: 40,
  filter: 'grayscale(100%)',
  '&:hover': {
    filter: 'grayscale(0)',
  },
  transition: 'filter 0.5s',
  '@media (max-width: 480px)': {
    filter: 'none',
    marginBottom: 30,
  },
});
