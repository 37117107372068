import React from 'react';
import PropTypes from 'prop-types';

import BackgroundImg from '@assets/images/hero_about.svg';
import BackgroundMobileImg from '@assets/images/hero_about_mobile.svg';

import {
  Wrapper, ContentWrapper, StyledImage, StyledMobileImage,
} from './styles';

const HeroSection = ({ content }) => (
  <Wrapper>
    <ContentWrapper>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </ContentWrapper>
    <StyledImage src={BackgroundImg} alt="" />
    <StyledMobileImage src={BackgroundMobileImg} alt="" />
  </Wrapper>
);

HeroSection.propTypes = {
  content: PropTypes.string,
};

export default HeroSection;
