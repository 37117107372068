import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import {
  Wrapper, ContentWrapper, CarouselWrapper, CarouselItemContainer, CarouselItem, StyledImg,
} from './styles';

class PressSection extends Component {
  renderCarousel = () => {
    const { data } = this.props;
    const settings = {
      focusOnSelect: true,
      swipeToSlide: true,
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      centerPadding: '0',
      dots: true,
      responsive: [
        {
          breakpoint: 480,
          settings: {
            dots: false,
          },
        },
      ],
    };
    return (
      <CarouselWrapper>
        <Slider {...settings}>
          {data.map(({ node: { entry } }, index, arr) => {
            if (index % 2 === 0) {
              return (
                <CarouselItemContainer key={index}>
                  <CarouselItem>
                    <StyledImg src={`http://cockpit.novotalk.com${entry.logo.path}`} alt={entry.name} />
                    {arr[index + 1]
                      && <StyledImg src={`http://cockpit.novotalk.com${arr[index + 1].node.entry.logo.path}`} alt={arr[index + 1].node.entry.name} />
                    }
                  </CarouselItem>
                </CarouselItemContainer>
              );
            }
            return null;
          })}
        </Slider>
      </CarouselWrapper>
    );
  }

  render() {
    const { content } = this.props;
    return (
      <Wrapper>
        <ContentWrapper>
          <div dangerouslySetInnerHTML={{ __html: content }} />
          {this.renderCarousel()}
        </ContentWrapper>
      </Wrapper>
    );
  }
}

PressSection.propTypes = {
  content: PropTypes.string,
  data: PropTypes.array,
};

export default PressSection;
