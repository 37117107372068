import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from '@components/seo';
import MainLayout from '@layouts/mainLayout';
import HeroSection from '@components/pages/about/hero';
import SolutionSection from '@components/pages/about/solution';
import TeamSection from '@components/pages/about/team';
import PressSection from '@components/pages/about/press';
import MissionSection from '@components/pages/about/mission';

class AboutPage extends Component {
  getSectionContent = (section) => {
    const { data } = this.props;
    return data.cockpitabout[section].childMarkdownRemark.html;
  }

  render() {
    const { data } = this.props;
    return (
      <MainLayout paintedMobileHeader>
        {/* TODO: add keywords */}
        <SEO title="About us" keywords={[]} />
        <HeroSection content={this.getSectionContent('hero')} />
        <SolutionSection content={this.getSectionContent('solution')} />
        <TeamSection content={this.getSectionContent('team')} data={data.allCockpitteam.edges} />
        {/* <PressSection content={this.getSectionContent('press')} data={data.allCockpitpress.edges} /> */}
        <MissionSection content={this.getSectionContent('mission')} />
      </MainLayout>
    );
  }
}

AboutPage.propTypes = {
  data: PropTypes.object,
};

export default AboutPage;

export const query = graphql`
  query AboutQuery {
    cockpitabout {
      hero: childCockpitaboutHeroTextNode {
        childMarkdownRemark {
          html
        }
      }
      solution: childCockpitaboutSolutionTextNode {
        childMarkdownRemark {
          html
        }
      }
      team: childCockpitaboutTeamTextNode {
        childMarkdownRemark {
          html
        }
      }
      press: childCockpitaboutPressTextNode {
        childMarkdownRemark {
          html
        }
      }
      mission: childCockpitaboutMissionTextNode {
        childMarkdownRemark {
          html
        }
      }
    }
    allCockpitteam {
      edges {
        node {
          entry {
            name
            position
            text
            image {
              path
            }
          }
        }
      }
    }
    allCockpitpress {
      edges {
        node {
          entry {
            name
            logo {
              path
            }
          }
        }
      }
    }
  }
`;
